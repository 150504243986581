<template>
    <div class="StatLead">

        <Card>

            <StatLeadFilters
                v-model:filters="filters"
                :leads-loaded="!is_loading"
                :tableLeadColumns = "tableLeadColumnsLength"
                @customize-table="showCustomizeTable()"
            ></StatLeadFilters>

            <div class="StatLead__table">
                <div class="table__wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left" v-if="tableLeadColumns.timestamp">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Date')"
                                        :isActive="sortBy==='Date'"
                                        @sorted="setSortingByDate"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left" v-if="tableLeadColumns.guid">{{ $lng.__('lk_webmaster_main', 'GUID') }}</th>
                                <th class="text-left" v-if="tableLeadColumns.geo">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Geo')"
                                        :isActive="sortBy==='Geo'"
                                        @sorted="setSortingByGeo"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left" v-if="tableLeadColumns.stream">{{ $lng.__('lk_webmaster_main', 'Stream') }}</th>
                                <th class="text-left" v-if="tableLeadColumns.offer">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Offer')"
                                        :isActive="sortBy==='Offer'"
                                        @sorted="setSortingByOffer"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left" v-if="tableLeadColumns.rate">{{ $lng.__('lk_webmaster_main', 'Rate') }}</th>
                                <th class="text-left" v-if="tableLeadColumns.bonus">{{ $lng.__('lk_webmaster_main', 'Bonuses') }}</th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.utm">UTM Medium</th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.utm">UTM Campaign</th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.utm">UTM Content</th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.utm">UTM Term</th>
                                <th class="text-left" v-if="tableLeadColumns.status">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Status')"
                                        :isActive="sortBy==='Status'"
                                        @sorted="setSortingByStatus"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.client_gender">{{ $lng.__('lk_webmaster_main', 'Client Gender') }}</th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.client_age">{{ $lng.__('lk_webmaster_main', 'Client Age') }}</th>
                                <th class="text-left text-nowrap" v-if="tableLeadColumns.indication_by_customer_check">{{ $lng.__('lk_webmaster_main', 'Indication by customer check') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(lead, index) in leadsSorted" :key="index">
                                <td class="text-nowrap" v-if="tableLeadColumns.timestamp">
                                    {{ lead.timestamp }}
                                </td>
                                <td class="text-nowrap" v-if="tableLeadColumns.guid">
                                    {{ lead.guid }}
                                </td>
                                <td v-if="tableLeadColumns.geo">
                                    {{ lead.geo }}
                                </td>
                                <td class="text-nowrap" v-if="tableLeadColumns.stream">
                                    {{ lead.stream.name || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td class="text-nowrap"  v-if="tableLeadColumns.offer">
                                    {{ lead.offer.name}}
                                </td>
                                <td class="text-nowrap" v-if="tableLeadColumns.rate">
                                    {{ lead.rate.currency ? lead.rate.currency : '&euro;'}} {{ lead.rate.amount }}
                                </td>
                                <td v-if="tableLeadColumns.bonus">
                                    {{ lead.bonus }}
                                </td>
                                <td v-if="tableLeadColumns.utm">
                                    {{ lead.utm.utm_medium || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="tableLeadColumns.utm">
                                    {{ lead.utm.utm_campaign || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="tableLeadColumns.utm">
                                    {{ lead.utm.utm_content || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="tableLeadColumns.utm">
                                    {{ lead.utm.utm_term || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="tableLeadColumns.status">
                                    {{ lead.status }}
                                </td>

                                <td v-if="tableLeadColumns.client_gender">
                                    {{ $lng.__('lk_webmaster_main', lead.client_gender) ||  $lng.__('lk_webmaster_main', 'Not enough data to show this report')  }}
                                </td>
                                <td v-if="tableLeadColumns.client_age">
                                    {{ lead.client_age || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="tableLeadColumns.indication_by_customer_check">
                                    <div class="indicator indicator_red" v-if="parseFloat(lead.indication_by_customer_check.amount) > 0 && parseFloat(lead.indication_by_customer_check.amount) <= 90">
                                        {{ $lng.__('lk_webmaster_main', 'Bad result') }}                                    
                                    </div>
                                    <div class="indicator indicator_yellow" v-else-if="parseFloat(lead.indication_by_customer_check.amount) > 90 && parseFloat(lead.indication_by_customer_check.amount) < 125">
                                        {{ $lng.__('lk_webmaster_main', 'Average result') }}
                                    </div>
                                    <div class="indicator indicator_green" v-else-if="parseFloat(lead.indication_by_customer_check.amount) >= 125">
                                        {{ $lng.__('lk_webmaster_main', 'Good result') }}        
                                    </div>
                                    <div class="indicator" v-else>  {{ $lng.__('lk_webmaster_main', 'Not enough data to show this report') }} </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </Card>

    </div>

    <!-- MODAL -->
    <Modal :title="$lng.__('lk_webmaster_main', 'Customize table')" v-if="showModal" @close="closeModal" addclass="customize-table">
        <div class="customize-table__wrapper">
            <div class="customize-table__item" v-for="(key, index) in Object.keys(this.tableLeadColumns)" :key="index">
                <FormSwitch :label="$lng.__('lk_webmaster_offers', key)" @update:checked="switchColumn(key)" :checked="tableLeadColumns[`${key}`]"></FormSwitch>
            </div>
        </div>
        <div class="customize-table__button-wrapper">
            <button
                @click="resetCustomizeTableFilters()"
                class="wv-btn--green wv-btn--text wv-btn--block"
            >
                {{ $lng.__('lk_webmaster_main', 'Reset') }}
            </button>
            <button
                @click="saveTableLeadColumns()"
                class="wv-btn--green wv-btn--text wv-btn--block"
            >
                {{ $lng.__('lk_webmaster_main', 'Save columns') }}
            </button>
        </div>
    </Modal>

</template>

<script>

import StatLeadFilters from './StatLeadFilters.vue';

export default {
    name: 'StatLead',
    props: [],
    data() {
        return {
            is_loading: true,
            sortBy: 'Date',
            sortDirection: 'desc',
            filters: {
                dateRange: {
                    startDate: (new Date),
                    endDate: (new Date)
                },
                byStream: [],
                byCountry: [],
                byOffer: [],
                byStatus: false,
                byGuid: '',
                byUtmContent: '',
                byUtmMedium: '',
                byUtmCampaign: '',
                byUtmTerm: '',
            },
            tableLeadColumns: {},
            showModal: false,
        };
    },
    computed: {
        leads() {
            return collect(this.$store.state.webmaster.leads)
                .map(lead => {
                    lead.status = this.$lng.__('lk_webmaster_main', lead.status);
                    return lead;
                })
                .all();
        },
        tableLeadColumnsLength(){
            return Object.keys(this.tableLeadColumns).length;
        },
        // сортировки
        leadsSorted() {
            let sorted = this.leadsFiltered.sort((a, b) => {

                // сортировка по Date
                if (this.sortBy === 'Date') {
                    let first = (typeof a.timestamp === 'object') ? '' : a.timestamp;
                    let last = (typeof b.timestamp === 'object') ? '' : b.timestamp;
                    return first.localeCompare(last);
                }

                // сортировка по Geo
                if (this.sortBy === 'Geo') {
                    let first = (typeof a.geo === 'object') ? '' : a.geo;
                    let last = (typeof b.geo === 'object') ? '' : b.geo;
                    return first.localeCompare(last);
                }

                // сортировка по Offer
                if (this.sortBy === 'Offer') {
                    let first = a.offer.name || '';
                    let last = b.offer.name || '';
                    return first.localeCompare(last);
                }

                // сортировка по Status
                if (this.sortBy === 'Offer') {
                    let first = a.status || '';
                    let last = b.status || '';
                    return first.localeCompare(last);
                }

            });

            let needReverse = this.sortDirection === 'desc';

            return needReverse ? sorted.reverse() : sorted;
        },
        // фильтрации
        leadsFiltered() {
            return this.leads.filter(lead => {
                let pass = true;

                // фильтр по GUID
                if (pass && this.filters.byGuid.length > 0) {
                    let query = this.filters.byGuid.toLowerCase();
                    pass = lead.guid.toLowerCase().indexOf(query) >= 0;
                }

                // фильтр по UTM Content
                if (pass && this.filters.byUtmContent.length > 0) {
                    let query = this.filters.byUtmContent.toLowerCase()
                    let string = lead.utm.utm_content === null ? '' : lead.utm.utm_content
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по UTM Medium
                if (pass && this.filters.byUtmMedium.length > 0) {
                    let query = this.filters.byUtmMedium.toLowerCase()
                    let string = lead.utm.utm_medium === null ? '' : lead.utm.utm_medium
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по UTM Campaign
                if (pass && this.filters.byUtmCampaign.length > 0) {
                    let query = this.filters.byUtmCampaign.toLowerCase()
                    let string = lead.utm.utm_campaign === null ? '' : lead.utm.utm_campaign
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по UTM Term
                if (pass && this.filters.byUtmTerm.length > 0) {
                    let query = this.filters.byUtmTerm.toLowerCase()
                    let string = lead.utm.utm_term === null ? '' : lead.utm.utm_term
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по Status
                if (pass && this.filters.byStatus) {
                    pass = this.filters.byStatus === lead.status
                }

                // фильтр по geo
                if (pass && this.filters.byCountry.length > 0) {
                    pass = false;
                    let geoTerms = collect(this.filters.byCountry)
                        .flatten()
                        .unique()
                        .all()
                        .join('|');
                    if (geoTerms.indexOf(lead.geo) !== -1) pass = true;
                }

                // фильтр по офферу
                if (pass && this.filters.byOffer.length > 0) {
                    pass = false;
                    let terms = collect(this.filters.byOffer)
                        .pluck('id')
                        .flatten()
                        .unique()
                        .all()
                        .join('|');
                    if (terms.indexOf(lead.offer.id) !== -1) pass = true;
                }

                // фильтр по потоку
                if (pass && this.filters.byStream.length > 0) {
                    pass = false;
                    let terms = collect(this.filters.byStream)
                        .pluck('id')
                        .flatten()
                        .unique()
                        .all()
                        .join('|');
                    if (terms.indexOf(lead.stream.id) !== -1) pass = true;
                }

                return pass;
            });
        },

        serializedFilters() {
            let filters = {
                from: this.filters.dateRange.startDate.getFormattedDate() + ' 00:00:00',
                to: this.filters.dateRange.endDate.getFormattedDate() + ' 23:59:59',
                limit: 5000,
            };

            if (this.filters.byStream.length) {
                filters.stream_id = this.filters.byStream[0].id
            }

            return (new URLSearchParams(filters)).toString();
        },

    },
    methods: {
        setSorting(by, direction) {
            this.sortBy = by;
            this.sortDirection = direction;
        },
        setSortingByDate(direction) {
            this.setSorting('Date', direction);
        },
        setSortingByGeo(direction) {
            this.setSorting('Geo', direction);
        },
        setSortingByOffer(direction) {
            this.setSorting('Offer', direction);
        },
        setSortingByStatus(direction) {
            this.setSorting('Status', direction);
        },
        getStatLeads() {
            console.log('getStatLeads()');
            this.is_loading = true;
            this.$store.dispatch('webmaster/getStatLeads', this.serializedFilters)
                .then(() => {
                    if(this.leads.length > 0 && !localStorage.getItem('tableLeadColumns')){
                        this.tableLeadColumns = this.leads[0]
                        let obj = {}
                        for (let [key] of Object.entries(this.leads[0])) {
                            obj[key] = key
                            obj[key] = true
                        }
                        this.tableLeadColumns = obj;
                    }

                    this.is_loading = false;
                });
        },
        setDateRange(dateRange) {
            this.filters = {...this.filters, dateRange: {startDate: dateRange.startDate, endDate: dateRange.endDate}};
        },
        showCustomizeTable(){
            this.showModal = !this.showModal;
        },
        closeModal() {
            this.showModal = false;
        },
        switchColumn(key){
            this.tableLeadColumns[`${key}`] = !this.tableLeadColumns[`${key}`]
        },
        resetCustomizeTableFilters(){
            for(let item in this.tableLeadColumns) {
                this.tableLeadColumns[`${item}`] = true
            }
            if(localStorage.getItem('tableLeadColumns')){
                localStorage.removeItem("tableLeadColumns");
            }
            this.closeModal()
        },
        saveTableLeadColumns(){
            let data = this.tableLeadColumns;
            localStorage.setItem('tableLeadColumns', JSON.stringify(data));
            this.closeModal();
        },
        getTableLeadColumns(){            
            if(localStorage.getItem('tableLeadColumns')){
                this.tableLeadColumns = JSON.parse(localStorage.getItem('tableLeadColumns'));                
            }
        }

    },
    watch: {
        filters: {
            deep: true,
            handler(val, oldVal) {
                this.getStatLeads();
            }
        }
    },
    beforeMount() {
        this.getTableLeadColumns();
    },
    beforeUnmount() {
        this.$store.commit('webmaster/clean', ['leads'])

    },
    components: {
        StatLeadFilters,
    }
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.StatLead {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
    &__table {
        margin: -30px;
        tbody {
            tr{
                td{
                    text-align: center;
                    &.text-left{
                        text-align: left;
                    }
                }
            }
        }
    }
    .indicator{
        color: gray;
        &_red{
            color: red;
        }
        &_yellow{
            color: rgb(181, 181, 0);
        }
        &_green{
            color: green;
        }
    }
}
.customize-table{
    width: calc(100% - 60px)!important;
    @include minWidth(720) {
        width: calc(100% - 120px)!important;
    }
    @include minWidth(1440) {
        width: 1440px!important;
    }
    &__wrapper{
        padding: 20px 0 0;
        display: grid;
        gap:10px;
        grid-template-columns: 1fr;
        @include minWidth(720) {
            grid-template-columns: 1fr 1fr;
        }
        @include minWidth(1440) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &__item{
        background: gba(255, 255, 255, 1);
        &:nth-child(even){
            background: (251, 251, 251, 1)
        }
    }
    &__button-wrapper{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        width:min-content;
        padding:40px 0 20px;
        button:last-child{
            margin-left:20px;
        }
    }
}
</style>